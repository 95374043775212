import Section from 'src/components/sections/Section'
import styled, { css } from 'styled-components'

import { containerCenter } from '../utils/containerCenter'
import { mediaMax } from '../utils/mediaMax'

export const Container = styled(Section)`
  background-image: url('https://miess.vteximg.com.br/arquivos/ids/224044/Vitrine-Revenda-Desktop.jpg?v=637584111470230000');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;

  ${mediaMax(
    css`
      background-position-x: 66%;
    `,
    900
  )}

  padding-top: 30px;
  padding-bottom: 120px;
`

export const Content = styled.div`
  ${containerCenter}

  display: flex;

  ${mediaMax(
    css`
      flex-direction: column;
    `,
    1023
  )}
`

export const Column = styled.div`
  flex: 1;

  ${mediaMax(
    css`
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &.right {
        img {
          max-width: 577px;
          width: 100%;
          height: auto;
        }

        p {
        }
      }
    `,
    1023
  )}

  &.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #fff;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      max-width: 503px;

      span {
        color: #ff89b4;
        font-weight: 500;
        font-family: Baloo;
        line-height: 100%;
      }
    }
  }
`

export const Title = styled.h3`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  ${mediaMax(
    css`
      text-align: center;
    `,
    1023
  )}

  span,
  small {
    font-style: normal;
    font-weight: 700;
  }

  span {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(
      269.33deg,
      #ff9bc0 7.68%,
      #ee368a 100.12%,
      #ff0074 100.6%
    );
    -webkit-background-clip: text;
    background-clip: text;
    font-family: 'Baloo';
    font-size: 50px;
    text-shadow: 3px -3px 4px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;

    ${mediaMax(
      css`
        font-size: 40px;
        line-height: 42px;
      `,
      380
    )}
  }

  small {
    color: #fff;
    font-size: 40px;
    ${mediaMax(
      css`
        font-size: 30px;
      `,
      460
    )}
  }
`

export const Form = styled.form`
  > div {
    display: flex;
    flex-direction: column;
    max-width: 433px;
    width: 100%;
    margin-right: auto;

    ${mediaMax(
      css`
        margin-left: auto;
      `,
      1023
    )}
  }
  h3 {
    color: #fff;
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 77px;

    ${mediaMax(
      css`
        display: flex;
        flex-direction: column;
        text-align: center;
      `,
      1023
    )}

    span {
      -webkit-text-fill-color: transparent;
      background: linear-gradient(
        269.33deg,
        #ff9bc0 7.68%,
        #ee368a 100.12%,
        #ff0074 100.6%
      );
      -webkit-background-clip: text;
      background-clip: text;
      font-family: 'Baloo';
      font-size: 40px;
      font-style: italic;
      font-weight: 700;
      text-shadow: 3px -3px 4px rgba(0, 0, 0, 0.3);
      text-transform: uppercase;
      padding-right: 5px;
      line-height: 100%;
    }
  }
`

export const Input = styled.input`
  border: none !important;
  height: 54px !important;
  margin-bottom: 15px !important;
  padding-left: 17px !important;
  width: 100%;
  -moz-appearance: textfield !important;
`

export const SubmitButton = styled.button`
  background: linear-gradient(1turn, #ff9bc0 -9.09%, #ff0074 130.85%);
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  height: 66px;
  line-height: 66px;
  /* margin-bottom: 64px;
  margin-top: 30px; */
  margin: 30px auto 64px auto;
  text-align: center;
  width: 316px;
  font-family: Baloo;
`

export const Attendance = styled.p`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  max-width: 433px;
  width: 100%;
  margin-right: auto;

  ${mediaMax(
    css`
      margin-left: auto;
      margin-top: 0;
    `,
    1023
  )}

  span {
    background: rgba(135, 30, 79, 0.6);
    background-blend-mode: overlay;
    border-radius: 11.3684px;
    color: #fff;
    /* font-family: bahnschrift; */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    height: 48px;
    line-height: 48px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    width: 308px;
  }

  a {
    display: flex;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(13.71deg, #ff9bc0 22.38%, #ff58a4 107%);
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Baloo;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    padding-left: 18px !important;
    text-shadow: -3px 3px 4px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;

    &::after {
      background-image: url('https://miess.vtexassets.com/arquivos/whatsapp-revenda.png');
      background-size: contain;
      content: '';
      display: block;
      height: 47px;
      margin-left: 16px;
      width: 47px;
    }
  }
`

export const Footer = styled.footer`
  width: 100%;
  /* bottom: 0; */
  display: flex;
  /* left: 0; */
  padding: 0 5%;
  margin-top: -5%;
  position: relative;

  &::before {
    background-image: linear-gradient(45deg, #361d3a, #ab557b);
    bottom: 0;
    content: '';
    height: 72%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -2;
  }

  &::after {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: '';
    height: 72%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  > img {
    width: 20%;
    height: auto;
  }

  ${mediaMax(
    css`
      margin-top: -6%;
      > img {
        width: 28%;
      }
    `,
    599
  )}

  ${mediaMax(
    css`
      margin-top: -9%;
      > img {
        width: 41%;
      }
    `,
    439
  )}

  > div {
    margin-bottom: 3%;
    margin-left: 40px;
    margin-top: auto;

    ${mediaMax(
      css`
        margin-left: 10px;
      `,
      1230
    )}

    ${mediaMax(
      css`
        margin-bottom: 1%;
      `,
      820
    )}
  }

  .embla__slide {
    padding: 0 16px;
    img {
      width: 100%;
      height: auto;
    }
  }
  /* position: absolute; */
`

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    background-color: ${({ theme }) => theme.colors.primary_0};
    border-radius: 5px;
    font-family: 'Baloo';
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;

    small {
      font-size: 16px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary_1};
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`
