import type { PageProps } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { useCallback, useState } from 'react'
import { CustomLink } from 'src/components/common/CustomLink'
import Embla from 'src/components/ui/Embla'
import { Image } from 'src/components/ui/Image'
import useForm from 'src/hooks/useForm'

import * as S from '../styles/pages/revenda-catalogo'

const RevendaCatalogo: FCC<PageProps> = (props) => {
  const {
    location: { host },
  } = props

  const title = 'Revender por catálogo de Sex shop e Lingerie | Miess'

  const docTitle = 'Revenda Catálogo'

  const slug = 'revenda-catalogo'

  const description =
    'Encontre as A Miess oferece para você as melhores condições para Revender em catalogo Sex Shop e Lingerie.'

  const canonical = host !== undefined ? `https://${host}/${slug}` : `/${slug}`

  const { data: formData, handleInputChange } = useForm()
  const [success, setSuccess] = useState(false)

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      try {
        await fetch('/api/reseller', {
          method: 'post',
          body: JSON.stringify(formData),

          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
          },
        })
        setSuccess(true)
      } catch (err) {
        //
        if (err.response.status !== 409) {
          // eslint-disable-next-line no-alert
          alert('Ocorreu um erro.')
        }
      }
    },
    [formData]
  )

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: `/${slug}`,
            name: docTitle,
            position: 1,
          },
        ]}
      />
      <S.Container containerCenter={false} marginBottom="0">
        <S.Content>
          <S.Column>
            <S.Title>
              <span>seja uma revendedora</span>
              <small>#EscolhaMeuMundoM</small>
            </S.Title>
            {!success ? (
              <S.Form onSubmit={handleSubmit}>
                <h3>
                  <span>comece agora!</span>
                  Preencha os campos abaixo e baixe o catálogo em seu celular
                  GRATUITAMENTE
                </h3>
                <div>
                  <S.Input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Digite seu nome"
                    required
                    onChange={handleInputChange}
                  />
                  <S.Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Digite seu email"
                    required
                    onChange={handleInputChange}
                  />
                  <S.Input
                    type="number"
                    id="phone"
                    name="phone"
                    maxLength={10}
                    placeholder="Digite seu celular (apenas números)"
                    required
                    onChange={handleInputChange}
                  />
                  <S.SubmitButton type="submit">Baixar catálogo</S.SubmitButton>
                </div>
              </S.Form>
            ) : (
              <S.SuccessContainer>
                <CustomLink
                  to="https://blog.miess.com.br/2019/Catalogo2019/Catalogo_Meu_Mundo_M_2019.pdf"
                  className="download"
                >
                  Catálogo online
                  <small>[clique aqui para baixar]</small>
                </CustomLink>
              </S.SuccessContainer>
            )}
            <S.Attendance>
              <span>Atendimento Exclusivo</span>
              <a
                href="https://web.whatsapp.com/send?phone=5511990120595"
                target="_blank"
                rel="noreferrer"
              >
                (11) 99012-0595
              </a>
            </S.Attendance>
          </S.Column>
          <S.Column className="right">
            <p>
              <span>Revenda</span> uma variedade de produtos da marca{' '}
              <span>n° 1</span> em{' '}
              <span style={{ color: '#99438a' }}>SEX SHOP</span> no Brasil!
            </p>
            <div>
              <Image
                src="https://miess.vtexassets.com/arquivos/banner-catalogo.png"
                width={577}
                height={374}
                alt="Banner Catálogo"
                loading="eager"
                srcSet=""
                noFactors
              />
            </div>
          </S.Column>
        </S.Content>
      </S.Container>
      <S.Footer>
        <Image
          src="https://miess.vtexassets.com/arquivos/produtos-revenda.png"
          width={261}
          height={225}
          alt="Banner Diferenciais"
          loading="lazy"
          srcSet=""
          noFactors
        />
        <div>
          <Embla
            sliderPerView={5}
            autoplay={{ delay: 5000 }}
            loop
            breakpoints={{
              1023: 5,
              820: 4,
              600: 3,
              440: 2,
              1: 1,
            }}
          >
            <Image
              src="https://miess.vtexassets.com/arquivos/icone-horario-revenda.png"
              width={196}
              height={83}
              alt="Banner Diferenciais"
              loading="lazy"
              srcSet=""
              noFactors
              options={{
                fitIn: false,
              }}
            />
            <Image
              src="https://miess.vtexassets.com/arquivos/icone-inovadores-revenda.png"
              width={239}
              height={83}
              alt="Banner Diferenciais"
              loading="lazy"
              srcSet=""
              noFactors
              options={{
                fitIn: false,
              }}
            />
            <Image
              src="https://miess.vtexassets.com/arquivos/icone-financeira-revenda.png"
              width={255}
              height={83}
              alt="Banner Diferenciais"
              loading="lazy"
              srcSet=""
              noFactors
              options={{
                fitIn: false,
              }}
            />
            <Image
              src="https://miess.vtexassets.com/arquivos/icone-brindes-revenda.png"
              width={188}
              height={83}
              alt="Banner Diferenciais"
              loading="lazy"
              srcSet=""
              noFactors
              options={{
                fitIn: false,
              }}
            />
            <Image
              src="https://miess.vtexassets.com/arquivos/icone-vendafacil-revenda.png"
              width={171}
              height={83}
              alt="Banner Diferenciais"
              loading="lazy"
              srcSet=""
              noFactors
              options={{
                fitIn: false,
              }}
            />
          </Embla>
        </div>
      </S.Footer>
    </>
  )
}

export default RevendaCatalogo
