import { useCallback, useMemo, useState } from 'react'

type FormInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

function useForm<T = any>() {
  const [data, setData] = useState({} as T & Record<string, any>)

  const handleInputChange = useCallback((e: React.ChangeEvent<FormInput>) => {
    setData((old) => ({
      ...old,
      [e.target.name]: e.target.value,
    }))
  }, [])

  const handleInputMaskChange = useCallback(
    (
      e: React.ChangeEvent<FormInput>,
      functionMask: (value: string) => string
    ) => {
      e.target.value = functionMask(e.target.value)

      setData((old) => ({
        ...old,
        [e.target.name]: e.target.value,
      }))
    },
    []
  )

  const clear = useCallback(() => {
    setData({} as T & Record<string, any>)
  }, [])

  return useMemo(
    () => ({
      data,
      handleInputChange,
      handleInputMaskChange,
      clear,
    }),
    [data, handleInputChange, handleInputMaskChange, clear]
  )
}

export default useForm
